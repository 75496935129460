<template>
  <div class="full-height">
    <slot v-if="accessible" />
  </div>
</template>
<script lang="ts">
import di from '@/api/digital-interface'
import CsoRouter from '@/api/CsoRouter'
import LoggingService from '@/common/LoggingService'

export default {
  name: 'CsoPageAccess',
  data () {
    return {
      accessible: true
    }
  },

  methods: {
    checkIfLoginRequired () {
      const loginRequired = this.$route.meta!.loginRequired && !di.token?.isUserOrDemo()
      if (loginRequired) {
        this.accessible = false
        LoggingService.log(LoggingService.eventTypes.access, 'Login required')
        CsoRouter.isOpenArea = true
        const deepLink = this.$route.path.slice(1)
        this.$router.push({ name: 'login', params: { deepLink } })
      }
    },
    checkIfLoginForbidden () {
      const loginForbidden = this.$route.meta!.loginForbidden && di.token?.isUserOrDemo()
      if (loginForbidden) {
        this.accessible = false
        LoggingService.log(LoggingService.eventTypes.access, 'Login forbidden')
        CsoRouter.isOpenArea = false
        this.$router.push({ name: 'dashboard' })
      }
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler () {
        this.accessible = true
        this.checkIfLoginRequired()
        this.checkIfLoginForbidden()
        if (this.accessible === true) {
          LoggingService.log(LoggingService.eventTypes.access, 'Access granted')
        } else {
          LoggingService.log(LoggingService.eventTypes.access, 'Access denied')
        }
      }
    }
  }
}
</script>
