export default class StaticUrls {
  static passwordChange = '/passwortaendern'
  static passwordChangeSent = '/passwortaendern/sent'
  static deregisterSent = '/deregistrierung/sent'
  static contactData = '/meinprofil'
  static homepage = '/vertragskonto'
  static userProfile = '/benutzerprofil'
  static accountOverview = '/kontostandinformationen'
  static deliveryAndContract = '/lieferstelle'
  static tariffDetails = '/tarifdetails'
  static tariffChange = '/produktwechsel'
  static deleteAccount = '/deregistrierung'
  static deleteAccountNoGP = '/deregistrierung?KeinGP='
  static postbox = '/postfach'
  static addBusinessPartner = '/weiteresvertragskonto'
  static advertisementAgreement = '/werbeeinverstaendnis'
  static communication = '/kommunikation'
  static tellAFriend = '/freundewerben'
  static bankData = '/bankdaten'
  static login = '/login'
  static logout = '/logout'
  static consumptionOverview = '/verbrauch'
  static forgottenPassword = '/neuespasswort'
  static register = '/registrierung'
  static partPayment = '/abschlag'
  static errorPage = '/error?code=404'
  static contact = '/kontakt'
  static loginDemo = '/login?demo='
  static logoutPage = 'https://www.vattenfall.de/abmeldung-erfolgreich?showMsg=true&action=logout'
  static mobileAppVouchers = 'http://www.vattenfall.de/de/mein-vattenfall-gutscheine.htm'
  static vtflContactPhone = 'https://www.vattenfall.de/kundenservice-kontakt#telefon-kontakt'
  static smartFAQ = 'https://www.vattenfall.de/hilfe#/'
  static vtflMain = 'https://www.vattenfall.de/'
  static redirectBPNumber = '/lieferstelle?toMoveIn=&selectedBusinessPartner='
  static redirectCANumber = '&vkNr='
  static move = '/umzug'
  static changeInvoiceAddress = '/rechnungsadresseaendern?selectedGP='
  static documentsId = '/s/postfach/document.pdf?documentId='
  static systemId = '&systemId='
  static meterReading = '/zaehler'
  static meterId = 'meterId='
  static contractAccountNumber = '&contractAccountNumber='
  static loadType = '&loadType='
  static startDate = '&startDate='
  static endDate = '&endDate='
  static contactForm = '/kontakt'
  static vtflElectricityTariffs = '//vattenfall.de/stromtarife'
  static vtflGasTariffs = '//vattenfall.de/gastarife'
  static vtflHeatTariffs = '//vattenfall.de/waermeversorgung'
  static vtflElectricityForCars = '//angebot.vattenfall.de/wallbox-oekostrom'
  static vtflSmartHome = '//vattenfall.de/smart-home'
  static vtflMove = '//vattenfall.de/umzug'
  static vtflPartPayment = '//vattenfall.de/abschlag'
  static vtflReportFault = '//vattenfall.de/stoerung-melden'
  static vtflVacancyPortal = '//vattenfall.de/gkt-leerstandsportal'
  static vtflMyHighlights = '//vattenfall.de/my-highlights'
  static vtflShop = '//vattenfall.tink.de/?cmp=TL-4087'
  static vtflTellAFriend = '//vattenfall.de/mein-vattenfall-freundewerben'
  static vtflCommercialOffer = '//vattenfall.de/marktkommunikation'
  static vtflInfoWorldEnergy = '//vattenfall.de/infowelt-energie'
  static vtflFraudsWarning = '//vattenfall.de/vorsicht-falle'
  static vtflLatePayment = '//vattenfall.de/zahlungsverzug'
  static vtflEnglish = '//vattenfall.de/electricity-supply-germany'
  static vtflItalian = '//vattenfall.de/fornitura-energia-elettrica-germania'
  static vtflFrench = '//vattenfall.de/approvisionnement-electricite-allemagne'
  static vtflPolish = '//vattenfall.de/niemczech-dostawa-energii-elektrycznej'
  static vtflRussian = '//vattenfall.de/russian-electricity-supply-germany'
  static vtflTurkish = '//vattenfall.de/almanya-elektrik-tedariki'
  static vtflEnergyInFocus = '//group.vattenfall.com/de/energie'
  static vtflNewsroom = '//group.vattenfall.com/de/newsroom'
  static vtflCareer = '//careers.vattenfall.com/de'
  static vtflImprint = '//vattenfall.de/impressum'
  static vtflTermsOfService = '//vattenfall.de/agb'
  static vtflPrivacy = '//vattenfall.de/datenschutz'
  static vtflGlossary = '//vattenfall.de/glossar'
  static vtflContact = '//vattenfall.de/kontakt'
  static vtflSaveEnergy = '//vattenfall.de/infowelt-energie/energie-sparen'
  static vtflInside = '//vattenfall.de/inside'
  static vtflAbout = '//vattenfall.de/ueber-vattenfall'
  static vtflGroup = '//group.vattenfall.com/de'
  static vtflSustainability = '//group.vattenfall.com/de/wer-wir-sind/nachhaltigkeit'
  static vtflClimateWebsite = 'https://fpm.climatepartner.com/tracking/21998-2212-1001/de?utm_source=(direct)&utm_medium=climatepartner.com'
  static sspCancelation = 'https://online.vattenfall.de/kuendigung?client=KSO'
  static demoUserThankYouPage = '/login?demoThankYou'
  static sessionLostLoginPage = '/login?sessionLost'
  static ipBlockedLoginPage = '/login?userBlocked'

  static withDate (link) {
    return `${this[link]}|${this.currentFormattedDate()}`
  }

  static currentFormattedDate () {
    let currentDatetime = new Date()
    return `${currentDatetime.getDate()}-${('0' + (currentDatetime.getMonth() + 1)).slice(-2)}-${currentDatetime.getFullYear()}`
  }
}
