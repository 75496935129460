export default class PropertyService {
  static propertiesName = 'properties.json'

  static properties = {
    DI_HOST: '',
    DI_SUBSCRIPTION_KEY: '',
    DI_STATE_SINCE: '',
    AI_CONNECTION_STRING: '',
    AI_ENABLED: '',
    CSP_META: '',
    FRIENDLY_CAPTCHA_SITEKEY: '',
    TRACK_ENABLED: '',
    TRACK_ACCOUNTS: [''],
    TARIFF_CHANGE_CAMPAIGN_CODE: '',
    MOVE_IN_OPEN_CAMPAIGN_CODE: '',
    MOVE_IN_CLOSED_CAMPAIGN_CODE: '',
    MAX_TIME_DIFF: ''
  }

  static get diHost () {
    return this.properties.DI_HOST
  }

  static get diSubscriptionKey () {
    return this.properties.DI_SUBSCRIPTION_KEY
  }

  static get diStateSince () {
    return this.properties.DI_STATE_SINCE
  }

  static get aiConnectionString () {
    return this.properties.AI_CONNECTION_STRING
  }

  static get aiEnabled () {
    return this.properties.AI_ENABLED
  }

  static get friendlyCaptchaSitekey () {
    return this.properties.FRIENDLY_CAPTCHA_SITEKEY
  }

  static get trackEnabled () {
    return this.properties.TRACK_ENABLED
  }

  static get trackAccounts () {
    return this.properties.TRACK_ACCOUNTS
  }

  static get tariffChangeCampaignCode () {
    return this.properties.TARIFF_CHANGE_CAMPAIGN_CODE
  }

  static get moveInOpenCampaignCode () {
    return this.properties.MOVE_IN_OPEN_CAMPAIGN_CODE
  }

  static get moveInClosedCampaignCode () {
    return this.properties.MOVE_IN_CLOSED_CAMPAIGN_CODE
  }

  static get CSPMetaTag () {
    return this.properties.CSP_META
  }

  static get maxTimeDiff () {
    const value = this.properties.MAX_TIME_DIFF * 1000
    const oneHour = 1 * 60 * 60 * 1000
    return value || oneHour
  }

  static isPrd () {
    const host = window.location.host
    const hostCso = 'service.vattenfall.de'
    const hostVfde = 'vattenfall.de'
    return [hostCso, hostVfde].includes(host)
  }

  static getPropertiesUrl () {
    const propertiesName = PropertyService.propertiesName
    const now = Date.now()
    return `/${propertiesName}?timestamp=${now}`
  }

  static async getProperties (callback) {
    try {
      const response = await fetch(this.getPropertiesUrl())
      const properties = await response.json()
      if (this.isPrd() && properties.DI_STATE_SINCE) {
        properties.DI_STATE_SINCE = ''
      }
      this.properties = properties
    } finally {
      callback()
    }
  }
}
